import React, { useEffect, useState } from "react";
import mowaamahLogo from "../../../assets/images/footer-right-logo.png";
import hrsdLogo from "../../../assets/images/hrsd-emblem-color.svg";
import mowaamahLogoWhite from "../../../assets/images/mowaamah-logo-white.svg";
import hrsdLogoWhite from "../../../assets/images/hrsd-logo-white.svg";
import aptLogoWhite from "../../../assets/images/apt-logo-white.png";
import takamolLogoWhite from "../../../assets/images/takamol-logo-white.svg";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { landingRoutes } from "../../../lookups/status";
import { Col, Row, Container } from "react-bootstrap";

const Footer = () => {
  const local = useSelector((state) => state.homeReducer.local);
  let location = useLocation();
  const [isLanding, setIsLanding] = useState(false);

  useEffect(() => {
    const checkLandingPage = landingRoutes.filter((route) =>
      route.includes(location.pathname)
    );
    if (checkLandingPage.length > 0) {
      setIsLanding(true);
    } else {
      setIsLanding(false);
    }
  }, [location]);

  return (
    <>
      {isLanding ? (
        <section className="landing-footer bg-primary">
          <Container>
            <Row>
              <Col xs={12} lg={3} className="mb-2 mb-lg-0">
                <img
                  src={mowaamahLogoWhite}
                  alt="Mowaamah logo"
                  className="mowaamah-footer-logo"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/564"
                >
                  <img
                    alt="DigitalStamp"
                    className="mowaamah-footer-logo mt-3"
                    src="http://raqmi.dga.gov.sa/platforms/DigitalStamp/GetStampWFile/3661"
                  />
                </a>
              </Col>
              <Col xs={12} lg={3} className="mb-4 mb-lg-0">
                <p className="font-weight-bold fs-5">{local.importantLinks}</p>
                <ul className="p-0 mb-0">
                  <li>
                    <Link to="/about" className="d-block">
                      {local.aboutUs}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth" className="d-block">
                      {local.getCertificate}
                    </Link>
                  </li>
                  {/* <li>
                    <a href="/home" className="d-block">
                      {local.mowaamahCompanies}
                    </a>
                  </li> */}
                </ul>
              </Col>
              <Col xs={12} lg={2}>
                <p className="font-weight-bold fs-5">{local.help}</p>
                <ul className="p-0 mb-0">
                  <li>
                    <Link to="/faq" className="d-block">
                      {local.FAQs}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="d-block">
                      {local.contactUs}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col xs={12} lg={4}>
                {/* video calling option on footer */}
                {/* <Row className="my-3">
                  <Col>
                    <Link
                      to="/customer-video-calling-service"
                      className="text-white px-5 py-3 border border-light fs-6"
                    >
                      <i className="bi bi-box-arrow-up-right mx-2"></i>
                      {local.signLanguageSupport}
                    </Link>
                  </Col>
                </Row> */}
                <Row className="align-items-center h-100 gx-lg-5">
                  <Col xs={4}>
                    <img
                      src={hrsdLogoWhite}
                      alt="HRSD logo"
                      className="footer-hrsd-logo w-100"
                    />
                  </Col>
                  <Col xs={4}>
                    <img
                      src={aptLogoWhite}
                      alt="APT logo"
                      className="footer-apd-logo w-100"
                    />
                  </Col>
                  <Col xs={4}>
                    <img
                      src={takamolLogoWhite}
                      alt="Takamol logo"
                      className="footer-takamol-logo w-75"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="copyright-section">
              <Col xs={12} lg={4}>
                <p className="">
                  <Link className="text-white mx-2" to="/terms-condition">
                    {local.termsConditions}
                  </Link>{" "}
                  <span className="opacity-25">|</span>
                  <Link className="text-white mx-2" to="/privacy-policy">
                    {local.privacyPolicy}
                  </Link>
                </p>
              </Col>
              <Col xs={12} lg={4}>
                <p>
                  {local.mowaamah} &copy; {new Date().getFullYear()}
                </p>
              </Col>
              <Col
                xs={12}
                lg={4}
                className="d-flex justify-content-lg-end px-0"
              >
                <a
                  className="text-white mx-3"
                  target="_blank"
                  href="https://instagram.com/mowaamahsa/"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  className="text-white mx-3"
                  target="_blank"
                  href="https://www.linkedin.com/company/mowaamahsa?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a
                  className="text-white mx-3"
                  target="_blank"
                  href="https://twitter.com/MowaamahSA"
                >
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a
                  className="text-white mx-3"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCjfgMnKbu3sczrdCCYiuHFw"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <div className="mowaamah-footer">
          <div className="grid text-center">
            <a className="g-col-2 g-col-md-1 mowaamah_logo" href="/">
              <img src={mowaamahLogo} alt="logo" aria-hidden="true" />
            </a>
            <a className="g-col-8 g-col-lg-2 g-col-md-3" href="/about">
              {local.aboutUs}
            </a>
            {/* <a className="g-col-8 g-col-lg-3 g-col-md-4" href="/">
              {local.mowaamahCompanies}
            </a> */}
            <a className="g-col-8 g-col-lg-3 g-col-md-4" href="/faq">
              {local.FAQs}
            </a>
            <a className="g-col-8 g-col-lg-2 g-col-md-3" href="/contact-us">
              {local.contactUs}
            </a>

            <a
              className="g-col-1 social-media"
              target="_blank"
              href="https://instagram.com/mowaamahsa/"
            >
              <i className="bi bi-instagram"></i>
            </a>
            <a
              className="g-col-1"
              target="_blank"
              href="https://www.linkedin.com/company/mowaamahsa?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf"
            >
              <i className="bi bi-linkedin"></i>
            </a>
            <a
              className="g-col-1"
              target="_blank"
              href="https://twitter.com/MowaamahSA"
            >
              <i className="bi bi-twitter-x"></i>
            </a>
            <a
              className="g-col-1"
              target="_blank"
              href="https://www.youtube.com/channel/UCjfgMnKbu3sczrdCCYiuHFw"
            >
              <i className="bi bi-youtube"></i>
            </a>
            <a className="g-col-2 mlsd_logo d-block d-lg-none" href="/">
              <img src={hrsdLogo} alt="logo" aria-hidden="true" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
