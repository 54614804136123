import React from "react";
import RiyalSymbol from "../../../assets/images/Saudi_Riyal_Symbol.svg";

function EnTerms() {
  return (
    <div>
      <div className="text-left">
        <h1 className="mb-5">
          Terms and Conditions of Use for the (Mowaamah) Platform
        </h1>
        <h2>First: Introduction</h2>
        <p className="text-justify">
          These Terms of Use (along with the documents referred to in this
          document) include the necessary guidelines on how to use and benefit
          from the website
          <a target="_blank" href="https://www.mowaamah.sa">
            https://www.mowaamah.sa
          </a>{" "}
          " (hereinafter referred to as "our website"), whether as a browser or
          as a user. The guidelines for using our website include procedures for
          logging in, browsing, or registering to use our website.
          <br />
          Please read these terms, conditions, and declarations carefully before
          using the Mowaamah platform. By using our website, you confirm your
          acceptance of these terms, conditions, and declarations and agree to
          abide by them.
          <br />
          These terms, conditions, and declarations, in addition to any
          additional terms applicable to your use of our website, are subject to
          continuous updates and changes as needed. Any modification or update
          to any of these terms and conditions becomes effective immediately
          upon adoption by the service provider. This requires you to
          continuously review these terms, conditions, and the limits of
          liability of the service provider and the operators of our website to
          know of any updates made to them. Your continued use of our website
          signifies your acknowledgment and full acceptance of any modifications
          made to its terms of use. Please note that these terms and conditions
          include intellectual property rights, and the service provider is not
          obligated to announce any updates or modifications made to these
          terms. Therefore, the Ministry of Human Resources and Social
          Development and Takaful for Business Services, as the service provider
          and operator of the Mowaamah program, reserve the right to change the
          terms and conditions from time to time.
        </p>
        <h2>Second: Purpose of Our Website</h2>
        <p className="text-justify">
          The Mowaamah program aims to enable people with disabilities to access
          suitable employment opportunities and provide them with all the
          facilities and tools to help them succeed. It also aims to encourage
          private sector establishments to provide a suitable work environment
          for people with disabilities, according to specific standards and
          requirements, enabling the establishment to obtain the necessary
          license to employ them.
        </p>
        <h2>Third: Definitions</h2>
        <p className="text-justify">
          The following words and phrases, wherever they appear here, have the
          meanings indicated alongside each of them unless the text explicitly
          indicates otherwise, or the context requires otherwise. Words
          appearing in the singular form shall include the plural form, and vice
          versa, if the text requires it. Also, titles and footnotes in these
          terms and conditions are not considered when interpreting them:
        </p>
        <ul>
          <li>
            The Ministry: The Ministry of Human Resources and Social
            Development.
          </li>
          <li>
            Our Website/The Website: The electronic platform of the Mowaamah
            program, which is the electronic information system provided through
            the Internet and owned by the Ministry of Human Resources and Social
            Development, aimed at providing services to empower people with
            disabilities.
          </li>
          <li>Service Provider: Takaful for Business Services Company.</li>
          <li>
            The Service: The process of enabling people with disabilities to
            access employment opportunities, starting from accessing the
            platform to empowerment.
          </li>
          <li>
            User: The browser and/or beneficiary of the service and/or the
            website.
          </li>
          <li>
            Establishment: Any entity licensed to operate in the Kingdom of
            Saudi Arabia by the relevant authority.
          </li>
        </ul>
        <h2>Fourth: Accessing Our Website</h2>
        <p className="text-justify">
          Our website is designed for use within the Kingdom of Saudi Arabia,
          and we do not guarantee the availability of our website's content
          elsewhere. We reserve the right to limit the provision of our website
          and regulate its services concerning anyone at any time. The user
          bears full responsibility for accessing our website from outside the
          Kingdom.
        </p>
        <h2>Fifth: Disclaimer of Reliance on Information</h2>
        <p className="text-justify">
          Despite the efforts made to update the information on our website, we
          do not explicitly or implicitly guarantee the accuracy, currency, or
          completeness of the content of our website, and we are not obligated
          to update it. You should seek professional or specialized consultation
          before making any decision or refrain from deciding based on the
          content of our website. We do not warrant that the content of our
          website will meet your needs or expectations, and we do not guarantee
          the accuracy, completeness, or quality of our website, its content, or
          the continuity of the availability of some of its parts.
        </p>
        <h2>Sixth: Intellectual Property Rights</h2>
        <ul>
          <li>
            The service provider owns all intellectual property rights to our
            website, and all materials published therein are subject to
            copyright laws, trademark regulations, patent laws of the Gulf
            Cooperation Council countries, international treaties, and all other
            relevant regulations, as all these rights are reserved.
          </li>
          <li>
            "Mowaamah" is a registered trademark in the Kingdom of Saudi Arabia
            according to the applicable regulations.
          </li>
          <li>
            It is noted that all intellectual property rights, including but not
            limited to trademarks, industrial designs, patents, and copyrights,
            and any content displayed on our website, are owned by their
            respective owners.
          </li>
          <li>
            The user is not permitted to use any of the intellectual property
            rights mentioned above, or any of their different forms, for
            promotional purposes, or to imply or give the impression, whether
            intentionally or unintentionally, of a relationship with the owner
            of the relevant trademark. Our website does not imply, whether
            explicitly, implicitly, or otherwise, the granting of any license or
            right to use any of the trademarks displayed on our website without
            the written permission of the owner of the relevant trademark.
          </li>
          <li>
            You are entitled to print one copy and download portions/quotations
            of the website's content for your personal use as interpreted by
            Saudi intellectual property laws. However, you are not allowed to
            modify the printed or digital copies of any materials you have
            printed or downloaded in any way, and you may not use any
            illustrative diagrams, photographs, video or audio sequences, or any
            other graphics separately.
          </li>
          <li>
            If you print, copy, or download any part of the website in violation
            of our terms and conditions, your right to use the website will be
            terminated immediately, and you will be required to return or
            destroy the materials you have copied.
          </li>
        </ul>
        <h2>Seventh: User Declarations</h2>
        <ul>
          <li>
            The user agrees to maintain the confidentiality of the information
            provided to them upon creating their user account and not to
            disclose it to a third party, as part of our security and privacy
            procedures including but not limited to: username, password, and any
            other information.
          </li>
          <li>
            The user acknowledges and agrees to adhere to all terms and
            conditions compatible with the laws and regulations of the Kingdom
            of Saudi Arabia and the regulations of the Ministry.
          </li>
          <li>
            The user acknowledges full responsibility for the information and
            inputs made by them when using our website, acknowledging, and
            understanding these terms, conditions, and declarations, in addition
            to other applicable terms and conditions, and complying with them.
          </li>
          <li>
            The user acknowledges full responsibility for all information
            provided by them or uploaded to our website, including without
            limitation digital inputs or documents uploaded, which the user must
            verify for accuracy and update. Thus, the user acknowledges that all
            information provided by them is accurate and up to date. The user
            also agrees to maintain the accuracy and update of this information.
            In the event of a breach of these terms and conditions or the
            issuance of fraudulent or forged documents, the user is subject to
            penalties and legal actions according to the provisions of the
            (Suspension and Termination) clause below and other applicable
            regulations.
          </li>
          <li>
            The user bears the responsibility to ensure that all users of the
            website, through their internet connection, comply with the terms of
            use and other applicable terms and conditions.
          </li>
          <li>
            The user acknowledges full responsibility for their own account on
            the website and acknowledges and guarantees that they are the sole
            user of the account. Therefore, all account activities are
            considered legally binding for the user as the account owner.
          </li>
          <li>
            The user acknowledges the service provider's right to use the data
            registered by them to conduct surveys and gather opinions, as well
            as utilizing them to develop the website and continuously improve
            the user experience.
          </li>
          <li>
            In case the account is used by several individuals or on behalf of
            an establishment, the person who registered the account under their
            name is responsible for all activities. The user must immediately
            inform the service provider, through the communication channels
            specified in these terms and conditions, of any unauthorized or
            illegal use of the account, or in case of suspicion that another
            person is aware of the user's account or password.
          </li>
          <li>
            The user acknowledges that the service provider or its
            representative has the right to verify any information provided by
            the user for the purpose of approving or rejecting the user's
            requests, or other actions where the data used by any government or
            private entities are utilized.
          </li>
        </ul>
        <h2>Eighth: Prohibited Uses</h2>
        <p className="text-justify">
          You can only use our website for legitimate purposes. You are not
          allowed to use it for prohibited uses, including but not limited to:
        </p>
        <ul>
          <li>
            Any method that violates any laws and regulations at the local,
            national, and/or international levels.
          </li>
          <li>
            Any illegal or fraudulent method, or any method that has an illegal
            or fraudulent effect to cause harm or attempt to harm others.
          </li>
          <li>
            Unauthorized access, interference, damage, or disruption of any part
            of the website, equipment, or network on which the website is
            stored, or software used in providing the website and any equipment,
            network, or software owned or used by any third party.
          </li>
          <li>
            Using the website in any way that aims to harm, disable, overload,
            or weaken any computer server or network(s) connected to our service
            server or interfere with the use of the website by any other party.
            You are not allowed to attempt unauthorized access to the website,
            or to any other accounts, computer systems, or networks connected to
            our service server through hacking and password cracking or any
            other means. Nor are you allowed to obtain or attempt to obtain any
            materials or information stored on the website, or on service
            computers or devices connected to them, through any means not
            intentionally made available through the website.
          </li>
          <li>
            Intentionally sending, receiving, uploading, downloading, using, or
            reusing any material that does not comply with our approved content
            standards as outlined below:
            <ol>
              <li>
                {" "}
                Transferring, or causing to be sent, any unwanted or
                unauthorized advertisements or promotional materials, or any
                other form of similar solicitation (spam).
              </li>
              <li>
                {" "}
                Knowingly transferring any data, sending, or uploading any
                material containing viruses, Trojan horses, worms, spyware, or
                any other harmful software or computer code designed to
                adversely affect the operation of any software, applications, or
                computer devices.
              </li>
              <li>
                {" "}
                Not deleting, or uploading otherwise in large quantities, any
                content from our website, including but not limited to, any user
                list or directory uploaded to the system, user posts, or user
                information. You also agree not to misrepresent or attempt to
                misrepresent your identity while using the website.
              </li>
              <li>
                {" "}
                Reproducing, duplicating, copying, or reselling any part of our
                website in a manner that violates the terms of use of this
                website.
              </li>
              <li>
                {" "}
                The establishment bears the responsibility for the accuracy of
                the answer if it does not meet the required standards to pass
                the qualification test.
              </li>
              <li>
                {" "}
                The establishment bears the responsibility for rejecting the
                request and not refunding the payment if it is proven that the
                data provided to pass the qualification is incorrect or
                misleading.
              </li>
            </ol>
          </li>
        </ul>
        <h2>Ninth: Limitation of Liability</h2>
        <p className="text-justify">
          These terms, conditions, and statements do not include any exceptions,
          liability, or limitation of liability for the service provider for
          death or personal injury or physical or moral damage arising from the
          use of the website.
          <br />
          The service provider and its managers, employees, agents, and
          subsidiaries will not be liable for any legal consequences arising
          from any losses or damages (whether direct or indirect) whatsoever
          arising from the use of the website, including but not limited to:
        </p>
        <ul>
          <li>
            Compliance with any contract and/or agreement entered by the user.
          </li>
          <li>
            Any payments to the wrong or incorrect account due to user input of
            incorrect information on the website.
          </li>
          <li>
            Any fraudulent, deceptive, or distortion of facts due to any act,
            omission, or negligence of the user.
          </li>
          <li>
            Any errors or misuse of the website or the inability of any person
            to use the website and understand its content.
          </li>
          <li>
            Any losses resulting from delayed internet connection or website
            suspension or errors resulting from the failure of any wired or
            wireless communication methods or any data transmission system,
            including server malfunction or maintenance, or failure to access
            the user page or pages linked to it, with the service provider being
            responsible for providing technical support and troubleshooting.
          </li>
          <li>
            Any losses resulting from the actions of any governmental authority
            or others, or any force majeure or irresistible force beyond the
            control of the service provider.
          </li>
        </ul>
        <p className="text-justify">
          The website may contain links and advertisements for applications and
          other websites, and such links should not be construed as endorsed by
          the service provider. Therefore, we do not assume any responsibility
          for the materials, services, or product offerings that appear on such
          applications and websites. We also do not guarantee the absence of
          viruses, malware, or other destructive nature from such applications
          and websites, and the user acknowledges and agrees that the service
          provider and the ministry are not responsible for the content or
          quality of any of these applications and websites.
          <br />
          In case of user violation of these terms and conditions, we reserve
          the right to suspend, cancel, or block the user from accessing the
          website and/or the account.
          <br />
          The service provider shall not be liable for any loss or damage
          resulting from a virus, unauthorized access to the website, or other
          harmful technical materials that may affect mobile phone devices and
          computer software/hardware or data or other materials belonging to the
          user as a result of the user's use of the website or the user's
          downloading of any content from the website, or from any other
          websites linked to our website.
          <br />
          Our website is not responsible for any legal problems related to user
          funds. Payments made by the user must be from their own funds, and
          they are not entitled to claim a refund unless stated in Article
          (Thirteen) of these terms and conditions.
          <br />
          Our website is not responsible for problems that may arise from
          internet interruption or others during payment operations, and the
          user is advised to consult their bank in this case.
        </p>
        <h2>Tenth: Linking to Our Website</h2>
        <ul>
          <li>
            You have the right to link to our homepage if you do so in a fair
            and lawful manner that does not damage the reputation of the website
            or misuse it.
          </li>
          <li>
            This linking should not be interpreted as a form of partnership or
            approval that implies any form of explicit or implicit endorsement
            or approval from us.
          </li>
          <li>
            {" "}
            Linking between our website and any other website not owned by you
            is prohibited.
          </li>
          <li>
            Posting any of our website's content on any other website is not
            allowed, and you may not create a link to any part of the website
            except the homepage.
          </li>
          <li>
            We reserve the right to revoke your linking license without prior
            notice.
          </li>
        </ul>
        <h2>Eleventh: Dealing with the Platform by Establishments</h2>
        <p className="text-justify">
          The platform requires establishments to meet several requirements to
          benefit from the platform's services and obtain conformity
          certification. These requirements can be summarized as follows:
        </p>
        <ul>
          <li>
            Registering the establishment on the platform and appointing an
            authorized user by the establishment.
          </li>
          <li>
            Passing the qualification test prepared by the platform, as the
            establishment's passing is the key factor determining the importance
            of the certificate granted.
          </li>
          <li>
            Payment by the establishment of the financial cost specified for
            issuing the certificate.
          </li>
          <li>Meeting the qualification test standards.</li>
          <li>Automatically issuing the certificate through the platform.</li>
        </ul>
        <h2>Twelfth: Service Costs</h2>
        <div className="text-justify">
          <ol>
            <li>
              {" "}
              Platform services require a financial cost for the service, and
              this cost has been determined at the following maximum limit:
            </li>
            <table className="table table-bordered mt-4 text-center">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">Entity</th>
                  <th className="text-center">Financial Cost</th>
                  <th className="text-center">Training Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Small/Medium</td>
                  <td>
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                    11,500
                  </td>
                  <td>
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                    2300
                  </td>
                </tr>
                <tr>
                  <td>Large/Giant</td>
                  <td>
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                    28,750
                  </td>
                  <td>
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                    5750
                  </td>
                </tr>
              </tbody>
            </table>
            <li>Payments are Non-refundable. </li>
          </ol>
        </div>
        <h2>
          Thirteenth: Failure of the Institution to Pass Qualification Tests
        </h2>
        <div>
          The platform has set a minimum threshold of 15% for institutions to
          meet the qualification requirements and obtain certification, aiming
          to incentivize institutions to achieve the qualification level.
          However, in the event that an institution fails to meet this minimum
          threshold of compliance suitability, the platform's team will take the
          following actions:
          <ol>
            <li>
              The team will communicate with the institution to clarify common
              errors within the qualification requirements that do not relate to
              performance or the institution's qualification (e.g., failure to
              disseminate the letter to employees, lack of accreditation stamp).
            </li>
            <li>
              The team will continue to communicate with the institution via
              email or direct contact, if necessary, to reopen the system and
              provide support to understand the compliance indicator and respond
              to related inquiries.
            </li>
            <li>
              The platform system allows three opportunities for the institution
              to reopen the system and attempt to raise its qualification level.
              If these opportunities are exhausted, the institution is advised
              of the importance of participating in the qualification training
              program to obtain a compliance certificate (the training program
              is optional and not mandatory).
            </li>
            <li>
              If the institution fails to meet the qualification requirements
              for the fourth time, it is considered to have exhausted its
              attempts, and the financial cost paid for the application is
              non-refundable.
            </li>
            <li>
              The institution can submit a new application at a new financial
              cost, which is not linked to the previous application.
            </li>
          </ol>
        </div>
        <h2>Fourteenth: Validity Period of Certificate Application</h2>
        <div className="text-justify">
          The subscription period to benefit from the platform's services is set
          at one year, during which the institution can benefit from the
          service, undergo evaluation, and receive certification upon payment of
          the specified financial cost. At the end of this period, the
          commitment under this subscription expires, and the institution will
          no longer be able to benefit from the platform's services.
          <br />
          Failure to benefit from the platform's services after this period
          includes the following:{" "}
          <ol>
            <li>Rejection of evaluation requests.</li>
            <li>Non-reissuance of the certificate.</li>
            <li>
              No refund for the specified financial cost, either in full or in
              part.
            </li>
          </ol>
        </div>
        <h2>Fifteenth: Compensation</h2>
        <p>
          By using our platform, you agree to indemnify, defend, and hold
          harmless the Ministry under the relevant regulations, Takamol
          Integration for Business Services Company and its subsidiaries,
          affiliates, directors, officers, agents, and employees from and
          against any claims, proceedings, or demands by third parties arising
          from or related to your use of our platform in any form. This includes
          any liabilities or expenses arising from all or some of the claims,
          losses, damages (actual and consequential), lawsuits,
          judgments,litigation costs, and attorney's fees. In such a case, we
          will provide you with written notice of such claims, lawsuits, or
          proceedings.
        </p>
        <h2>Sixteenth: Suspension and Termination</h2>
        <div className="text-justify">
          The service provider, at its discretion, will determine whether there
          is a violation of the terms of use by the user using the website. If
          it is found that there is indeed a violation of these terms and
          conditions, the service provider may take appropriate action.
          <br />
          Non-compliance with these terms and conditions, acknowledgments, or in
          cases of fraud or forgery by the user may lead to the service provider
          taking any or all of the following actions, including but not limited
          to:
          <ol>
            <li>
              Immediate, temporary, or permanent suspension of the user's right
              to use the website.
            </li>
            <li>
              Immediate, temporary, or permanent removal of any postings or
              materials uploaded by the user on the website.
            </li>
            <li>Issuance of a warning to the user.</li>
            <li>
              Legal action against the user to seek compensation for all costs
              (including, but not limited to, any damages or legal and
              administrative costs) resulting from the breach of these terms.
            </li>
            <li>
              Taking further legal action against the user and disclosing
              information to the relevant judicial authorities when necessary.
            </li>
          </ol>
          We disclaim any responsibility for the actions taken as a result of
          the breach of the terms of use, and such actions are not limited to
          those mentioned within the terms. We reserve the right to take any
          other appropriate action(s) that do not contradict Saudi Arabian
          regulations and laws.
        </div>
        <h2>Seventeenth: Payment Policy and Financial Transactions</h2>
        <div>
          All service prices offered on our website are inclusive of Value Added
          Tax (VAT). <br />
          Financial transactions conducted on the website are through the
          following official payment methods:
          <ol>
            <li>Bank transfer.</li>
            <li>Payment via SADAD.</li>
          </ol>
          The user is required to inform us immediately of any financial
          transactions conducted outside the website, as the website does not
          recognize any financial transactions conducted outside our platform.
        </div>
        <h2>Eighteenth: Applicable Law</h2>
        <p className="text-justify">
          These terms and conditions, along with their subject matter and
          formation, are governed by the laws and regulations applicable in the
          Kingdom of Saudi Arabia. If any provision of these terms and
          conditions is deemed invalid or unenforceable under the prevailing
          laws and regulations in the Kingdom of Saudi Arabia, it shall not
          affect the validity and enforceability of the remaining provisions and
          clauses on the website users. The courts of the Kingdom of Saudi
          Arabia, Riyadh City, have exclusive jurisdiction over this matter. The
          Arabic language is the primary language for using the website and
          benefiting from all the materials published on it. The translation of
          any of these materials is intended to provide an additional service,
          and therefore, the available translation shall not be relied upon in
          interpreting any disputes regarding the content of the website.
          Instead, Arabic shall be relied upon.
        </p>
        <h2>Nineteenth: Communication Methods</h2>
        <p className="text-justify">
          Users can contact customer service regarding any matters related to
          the system, website, or services, including, but not limited to,
          general inquiries, suggestions, claims, or disputes, by contacting
          customer service at the email address (
          <a href="mailto:info@mowaamah.com">info@mowaamah.com</a>) or by
          sending written notice to the customer service center. Notice shall
          not be considered received by customer service unless the user
          receives confirmation of receipt, whether electronically or in
          writing.
          <br />
          All notices and communications shall be sent to the address registered
          in the user's account, and notification by this means shall be
          considered official notification, whether electronic, postal, or
          manual. Such notifications shall be effective from the date of
          dispatch.
          <br />
          The user agrees to provide the service provider with any changes or
          modifications to their address or information.
        </p>
      </div>
    </div>
  );
}

export default EnTerms;
