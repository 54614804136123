import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NoMatch from "../components/Common/NoMatch";
import AuthenticatedComponentsWrapper from "../shared/AuthenticatedComponentsWrapper";
import Auth from "./Auth";
import SelectCompany from "../components/Home/SelectCompany";
import Loader from "../components/Common/Loader";
import { useSelector } from "react-redux";
import Company from "./Company";
import MainPage from "../components/Landing/main";
import AboutPage from "../components/Landing/about";
import FaqPage from "../components/Landing/faq";
import ContactPage from "../components/Landing/contact";
import PrivacyPolicy from "../components/Landing/privacy";
import TermsCondition from "../components/Landing/termscondition";
import ReactGA from "react-ga4";

const Router = () => {
  const tokenLoading = useSelector((state) => state.commonReducer.tokenLoading);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.search,
      title: location.pathname,
    });
  }, [location]);

  if (tokenLoading) {
    return <Loader />;
  } else {
    return (
      <Routes>
        <Route path="auth/*" element={<Auth />} />
        <Route
          path=":companyId/*"
          element={
            <AuthenticatedComponentsWrapper>
              <Company />
            </AuthenticatedComponentsWrapper>
          }
        />
        <Route
          path="/"
          element={
            <AuthenticatedComponentsWrapper>
              <SelectCompany />
            </AuthenticatedComponentsWrapper>
          }
        />
        <Route path="/home" element={<MainPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        {/* <Route
          path="/customer-video-calling-service"
          element={
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            >
              <VideoCallPage />
            </GoogleReCaptchaProvider>
          }
        /> */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    );
  }
};

export default Router;
