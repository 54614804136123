import axios from "axios";
import arLocal from "../localization/Arabic/index.json";
import enLocal from "../localization/English/index.json";
import { callRefreshToken, callLogout } from "../store/Auth/actions";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";

const local =
  localStorage.getItem("prefered_language") === "en" ? enLocal : arLocal;

export const AuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const PublicInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const VideoCallInstance = axios.create({
  baseURL: process.env.REACT_APP_VIDEO_CALL_API_URL,
});

AuthInstance.defaults.timeout = 60000;
PublicInstance.defaults.timeout = 20000;
VideoCallInstance.defaults.timeout = 20000;

export const setupAuthInterceptor = (store) => {
  AuthInstance.interceptors.request.use(
    async (request) => {
      const access = localStorage.getItem("access");
      const lang = localStorage.getItem("prefered_language");
      if (access) {
        request.headers["Authorization"] = `Bearer ${access}`;
      }
      request.headers["Accept-Language"] = lang;
      return request;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  AuthInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (!error.response) {
        var custom_error = {
          response: {
            status: 503,
            statusText: local.serviceUnavailable,
          },
        };
        return Promise.reject(custom_error);
      } else {
        const code = error.response.status;
        const originalRequest = error.config;
        const refresh = localStorage.getItem("refresh");
        const access = localStorage.getItem("access");
        const isOIDCAuth = localStorage.getItem("is_oidc_auth");
        const currentTime = Date.now() / 1000;
        if (
          (code === 401 || code === 403) &&
          error.config &&
          !originalRequest._retry &&
          refresh &&
          access
        ) {
          try {
            if (isOIDCAuth || jwtDecode(refresh).exp > currentTime) {
              originalRequest._retry = true;
              await store.dispatch(callRefreshToken({ refresh: refresh }));
              return AuthInstance(originalRequest);
            } else {
              await store.dispatch(callLogout());
            }
          } catch (error) {}
        }
        if (![400, 401, 403].includes(code)) {
          Sentry.captureException(error);
        }
        return Promise.reject(error);
      }
    }
  );
};

export const setupPublicInterceptor = () => {
  PublicInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (!error.response) {
        var custom_error = {
          response: {
            status: 503,
            statusText: local.serviceUnavailable,
          },
        };
        return Promise.reject(custom_error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  PublicInstance.interceptors.request.use(
    async (request) => {
      const lang = localStorage.getItem("prefered_language");
      request.headers["Accept-Language"] = lang;
      return request;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};
export const setupVideoCallInterceptor = () => {
  VideoCallInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (!error.response) {
        var custom_error = {
          response: {
            status: 503,
            statusText: local.serviceUnavailable,
          },
        };
        return Promise.reject(custom_error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  VideoCallInstance.interceptors.request.use(
    async (request) => {
      const lang = localStorage.getItem("prefered_language");
      request.headers["Accept-Language"] = lang;
      return request;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};
