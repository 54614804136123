import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import isEmpty from "../../../utils/isEmpty";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { Container } from "react-bootstrap";
import BackButton from "../../../shared/BackButton";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import useQuery from "../../../hooks/useQuery";
import { orderType } from "../../../lookups/status";
import RiyalSymbolGreen from "../../../assets/images/Saudi_Riyal_Symbol_Green.svg";

const CardDetails = () => {
  let { uuid, paymentMethod, companyId } = useParams();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const dispatch = useDispatch();
  const local = useSelector((state) => state.homeReducer.local);
  const query = useQuery();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const transaction = useSelector((state) => state.paymentReducer.transaction);
  const paymentWidgetRef = useRef(null);
  const nonce = btoa(new Date().getTime().toString());
  var order = useSelector((state) =>
    query.get("type") === orderType.certificate
      ? state.certificateReducer.assessmentOrder
      : query.get("type") === orderType.training
      ? state.trainingReducer.trainingOrder
      : ""
  );

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
    } else {
      navigate(`/${companyId}`);
    }

    const loadPaymentScripts = () => {
      // Payment Script
      const script = document.createElement("script");
      script.src = `${process.env.REACT_APP_GATEWAY_URL}${transaction?.checkout_id}`;
      script.async = true;
      script.crossOrigin = "anonymous";
      script.integrity = transaction.integrity;

      script.onload = () => {
        const paymentWidgetContainer = paymentWidgetRef.current;

        // Style Script
        const styleScript = document.createElement("script");
        styleScript.setAttribute("nonce", nonce);
        styleScript.innerHTML = `var wpwlOptions = {style: "plain", locale: '${local.language}'}`;
        paymentWidgetContainer.appendChild(styleScript);

        // Payment Form
        const form = document.createElement("form");
        form.action = `/${companyId}/payment/${uuid}/method/${paymentMethod}/${
          transaction?.checkout_id
        }/details/processing?type=${query.get("type")}`;
        form.setAttribute("class", "paymentWidgets");
        form.setAttribute("data-brands", paymentMethod);
        paymentWidgetContainer.appendChild(form);
      };

      // Append the script element to the head of the document
      document.head.appendChild(script);
    };

    if (isEmpty(uuid)) {
      navigate("/");
    } else if (isEmpty(transaction?.checkout_id)) {
      navigate(-1);
    } else {
      loadPaymentScripts();
    }
  }, []);
  // useLegacyEffect(() => {
  //   console.log("Adding CSP for Payment Page...");

  //   let cspMeta = document.querySelector(
  //     'meta[http-equiv="Content-Security-Policy"]'
  //   );

  //   const cspContent = `
  //     style-src 'self' https://eu-test.oppwa.com 'unsafe-inline';
  //     frame-src 'self' https://eu-test.oppwa.com;
  //     script-src 'self' https://eu-test.oppwa.com 'unsafe-inline';
  //     connect-src 'self' https://www.google-analytics.com https://mowaamah-stage-api.takamoldemo.net https://eu-test.oppwa.com https://*.ingest.sentry.io;
  //     img-src 'self' https://mowaamah-stage-api.takamoldemo.net https://eu-test.oppwa.com;
  //   `;

  //   if (cspMeta) {
  //     // Update existing CSP
  //     cspMeta.setAttribute("content", cspContent);
  //   } else {
  //     // Create new CSP meta tag
  //     cspMeta = document.createElement("meta");
  //     cspMeta.setAttribute("http-equiv", "Content-Security-Policy");
  //     cspMeta.setAttribute("content", cspContent);
  //     document.head.appendChild(cspMeta);
  //   }

  //   return () => {
  //     console.log("Removing CSP after leaving Payment Page...");
  //     if (cspMeta) {
  //       cspMeta.remove();
  //     }
  //   };
  // }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12">
          {query.get("type") === orderType.certificate && (
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          )}
          <BackButton onClick={() => navigate(-1)} label={local.backV2} />
          <div className="row justify-content-center mt-5">
            <div className="col-12 col-lg-8">
              <div className="card shadow p-4 p-lg-5">
                {/* Added Total Cost to show how much has to be paid */}
                <div className="mb-5 text-success fs-4 text-center">
                  <span className="mx-1">{local.totalCost}</span>
                  <span className="mx-1">
                    <img
                      src={RiyalSymbolGreen}
                      alt="SAR"
                      width="16"
                      height="16"
                      className="me-1 mb-1"
                    />
                    {order?.pricing_summary?.grand_total}
                  </span>
                </div>

                <div id="payment-widget" ref={paymentWidgetRef}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardDetails;
