import React from "react";
import RiyalSymbol from "../../../assets/images/Saudi_Riyal_Symbol.svg";

function ArTerms() {
  return (
    <div>
      <div className="text-left">
        <h1 className="mb-5">شروط وأحكام استخدام منصة (مواءمة)</h1>
        <h2>أولاً: مقدمة</h2>
        <p className="text-justify">
          شروط الاستخدام هذه (مع الوثائق المشار إليها في هذه الوثيقة) تتضمن
          الإرشادات اللازمة لكيفية استخدام والاستفادة من الموقع الالكتروني"{" "}
          <a target="_blank" href="https://www.mowaamah.sa">
            https://www.mowaamah.sa
          </a>{" "}
          " (والذي يشار إليه فيما بعد بـ "موقعنا")، سواء كمتصفح أو كمستخدم.
          تتضمن إرشادات استخدام موقعنا على إجراءات الدخول والتصفح أو التسجيل
          لاستخدام موقعنا.
          <br />
          يرجى قراءة الشروط والأحكام والإقرارات التالية بعناية قبل استخدامك موقع
          مواءمة، حيث إنك باستخدام موقعنا تؤكد على قبول هذه الشروط والأحكام
          والإقرارات والموافقة عليها وعلى الامتثال لما تتضمنه من أحكام.
          <br />
          تخضع هذه الشروط والأحكام والإقرارات بالإضافة إلى الشروط الإضافية
          المنطبقة على استخدامك موقعنا لتحديثات وتغييرات مستمرة حسب الحاجة،
          ويصبح أي تعديل أو تحديث لأي من هذه الشروط والأحكام نافذاً فور اعتماده
          من قِبل مزود الخدمة؛ وهو ما يتطلب منك مراجعة مستمرة لهذه الشروط
          والأحكام ومبادئ حدود مسؤولية مزود الخدمة والقائمين على موقعنا لمعرفة
          أية تحديثات تتم عليها؛ إذ أن استمرارك في استخدام موقعنا يعني اطلاعك
          وقبولك التام لأي تعديل تم على شروط استخدامها. علماً بأن هذه الشروط
          والأحكام تتضمن حقوق الملكية الفكرية، كما أن مزود الخدمة غير مطالب
          بالإعلان عن أية تحديثات أو تعديلات تتم على تلك الشروط، لذا تحتفظ وزارة
          الموارد البشرية والتنمية الاجتماعية وشركة تكامل لخدمات الأعمال بصفتها
          مزود الخدمة والشركة المشغلة لبرنامج مواءمة بحق تغيير الشروط والأحكام
          من وقت لآخر.
        </p>
        <h2>ثانياً: الهدف من موقعنا </h2>
        <p className="text-justify">
          يهدف برنامج موائمة إلى تمكين ذوي الإعاقة من الحصول على فرص عمل مناسبة،
          ومدّهم بكافة التسهيلات والأدوات التي تساعدهم على تحقيق النجاح، إضافة
          إلى تحفيز المنشآت في القطاع الخاص على توفير بيئة عمل ملائمة للأشخاص
          ذوي الإعاقة، وفق معايير واشتراطات محددة، تمكن المنشأة من الحصول على
          الترخيص اللازم لتوظيفهم.
        </p>
        <h2>ثالثاً : التعريفات</h2>
        <p className="text-justify">
          يقصد بالكلمات والعبارات التالية أينما وردت هنا المعاني المبينة إلى
          جانب كل منها ما لم يتضح من صراحة النص أو يقتضي سياق الكلام غير ذلك.
          تدل الكلمات الواردة بصيغة المفرد على ذات المدلول بصيغة الجمع، ويكون
          العكس صحيحاً أيضاً إذا تطلب النص ذلك. كما أن العناوين والهوامش الواردة
          في هذه الشروط والأحكام لا تؤخذ بعين الاعتبار عند تفسيرها:
        </p>
        <ul>
          <li>الوزارة: وزارة الموارد البشرية والتنمية الاجتماعية.</li>
          <li>
            موقعنا/الموقع: موقع برنامج مواءمة الالكتروني، وهو النظام المعلوماتي
            الالكتروني المقدم من خلال شبكة الإنترنت والمملوك لوزارة الموارد
            البشرية والتنمية الاجتماعية والمعني بتقديم خدمات تمكين ذوي الإعاقة.
          </li>
          <li>مزود الخدمة: شركة تكامل لخدمات الأعمال.</li>
          <li>
            الخدمة: عملية تمكين ذوي الإعاقة من فرص العمل ابتداءً من الوصول إلى
            المنصة وانتهاء بالتمكين.
          </li>
          <li>المستخدم: هو المتصفح و/أو المستفيد من الخدمة و/أو الموقع.</li>
          <li>
            المنشأة: أي جهة مرخصة للعمل في المملكة العربية السعودية من قبل الجهة
            ذات العلاقة.
          </li>
        </ul>
        <h2>رابعاً: الدخول لموقعنا</h2>
        <p className="text-justify">
          صُمم موقعنا للاستخدام داخل المملكة العربية السعودية ولا نضمن توفر
          محتوى موقعنا في أماكن أخرى. ويحق لنا الحد من توفير موقعنا وتقنين
          خدماته فيما يخص أي شخص في أي وقت كان، كما يتحمل المستخدم كامل مسؤولية
          دخوله موقعنا من خارج المملكة.
        </p>
        <h2>خامساً: عدم الاعتماد على المعلومات</h2>
        <p className="text-justify">
          بالرغم من الجهود المبذولة لتحديث معلومات موقعنا، إلا أننا لا نتعهد أو
          نضمن بشكل صريح أو ضمني دقة وحداثة وكمال محتوى موقعنا ونحن لسنا ملزمين
          بتحديثه، كما يجب عليك الحصول على الاستشارة الاحترافية أو المتخصصة قبل
          الإقدام على اتخاذ أي قرار أو التراجع عن قرار بناءً على محتوى موقعنا.
          لا نتعهد بأن محتوى موقعنا سيلبي احتياجاتك أو توقعاتك ولا نضمن دقة أو
          كمال أو جودة موقعنا أو محتواه أو استمرارية توفر بعضًا من أجزاءه.
        </p>
        <h2>سادساً: حقوق الملكية الفكرية</h2>
        <ul>
          <li>
            يملك مزود الخدمة كافة حقوق الملكية الفكرية لموقعنا، وجميع المواد
            المنشورة فيه تخضع لقوانين حماية حقوق المؤلف ونظام العلامات التجارية
            ونظام براءات الاختراع لدول مجلس التعاون لدول الخليج العربية
            والمعاهدات الدولية وجميع الأنظمة الأخرى ذات العلاقة، حيث جميع هذه
            الحقوق محفوظة.
          </li>
          <li>
            "مواءمة" هي علامة تجارية مسجلة في المملكة العربية السعودية وفق
            الأنظمة المعمول بها.
          </li>
          <li>
            يراعى أن جميع حقوق الملكية الفكرية التي تشمل ولا تنحصر على العلامات
            التجارية، النماذج الصناعية، براءات الاختراع وحقوق المؤلف، وأي محتوى
            يعرض على موقعنا والتي تظهر على الموقع هي ملك لأصحابها.
          </li>
          <li>
            لا يجوز للمستخدم استخدام أي من حقوق الملكية الفكرية المذكورة أعلاه،
            أو أي من أشكالها المختلفة، لأغراض ترويجية، أو تنطوي على ادعاء أو
            تعطي انطباع بقصد أو بغير قصد من حيث المظهر بوجود علاقة مع صاحب
            العلامة التجارية المعنية ولا يتضمن موقعنا على ما يفسر أنه يمنح
            -سواءً صراحةً أو ضمناً أو غير ذلك- أي ترخيص أو حق في استخدام أي من
            العلامات التجارية المعروضة على موقعنا دون إذن خطي من مالك العلامة
            التجارية المعنية.
          </li>
          <li>
            يحق لك طباعة نسخة واحدة وتحميل أجزاء/اقتباسات من محتوى الموقع
            لاستخدامك الشخصي كما فسرتها أنظمة الملكية الفكرية السعودية، كما لا
            يحق لك تعديل النسخ الورقية أو الرقمية لأي مواد قمت بطباعتها أو
            تنزيلها بأي طريقة، ولا يجوز أن تستخدم أي رسوم توضحيه أو صور
            فوتوغرافية أو سلسلة من المقاطع المرئية أو الصوتية أو أي رسومات
            بيانية أخرى.
          </li>
          <li>
            في حال القيام بطباعة أو نسخ أو تحميل أي جزء من الموقع إخلالًا لشروط
            وأحكام موقعنا فسيتم إيقاف حقك في استخدام الموقع فورًا، وستُلزم
            بإعادة أو إتلاف المواد التي قمت بنسخها.
          </li>
        </ul>
        <h2>سابعاً: إقرارات المستخدم</h2>
        <ul>
          <li>
            المحافظة على سرية المعلومات التي سيتم تزويدكم بها بعد إنشاءكم حساب
            المستخدم وعدم إفصاحها لطرف ثالث، وذلك كجزء من إجراءاتنا الخاصة بأمن
            وخصوصية الدخول على سبيل المثال لا الحصر: اسم المستخدم وكلمة المرور
            وأي معلومات أخرى.
          </li>
          <li>
            يقر المستخدم بإلمامه ومراعاته وتقيده لجميع الشروط والضوابط المتوافقة
            مع قوانين وأنظمة المملكة العربية السعودية وتنظيمات الوزارة.
          </li>
          <li>
            يقر المستخدم بمسؤوليته التامة عن المعلومات والمدخلات التي تتم من
            قبله عند استخدامه موقعنا، قر بمسؤوليته بالإلمام والإدراك بهذه الشروط
            والأحكام والإقرارات بالإضافة إلى الشروط والأحكام الأخرى المرعية،
            والامتثال لها.
          </li>
          <li>
            يقر المستخدم بمسؤوليته التامة عن جميع المعلومات التي يقدمها، أو التي
            يتم تحميلها في موقعنا وذلك يتضمن بلا حصر المدخلات الرقمية أو الوثائق
            التي تم تحميلها والتي يتوجب على المستخدم التحقق من دقتها وتحديثها،
            وبهذا فإن المستخدم يقر بأن جميع المعلومات المقدمة من طرفه هي معلومات
            دقيقة وحديثة. وأنه يوافق على المحافظة على هذه المعلومات من حيث دقتها
            وتحديثها. وعند وجود إخلال بهذه الشروط والأحكام أو صدور وثائق
            احتيالية أو مزورة يخضع المستخدم للعقوبات والإجراءات القانونية
            اللازمة وفق أحكام البند (التعليق والإنهاء) أدناه والأنظمة المرعية
            الأخرى.
          </li>
          <li>
            يتحمل المستخدم مسؤولية ضمان امتثال جميع مستخدمي الموقع عبر اتصال
            الإنترنت الخاص به بشروط الاستخدام والأحكام المطبقة الأخرى.
          </li>
          <li>
            يقر المستخدم بمسؤوليته التامة عن حسابه الخاص في الموقع ويقر ويضمن
            بأنه المستخدم الوحيد للحساب وعلى ذلك فجميع أنشطة الحساب تعتبر ملزمة
            قانونيا للمستخدم بصفته صاحب الحساب.
          </li>
          <li>
            يقر المستخدم بأحقية مزود الخدمة باستخدام البيانات المسجلة من قبله
            لعمل الاستبيانات وأخذ الآراء كما يتم الاستفادة منها لتطوير الموقع
            والتحسين المستمر لتقديم تجربة استخدام أكثر سهولة.
          </li>
          <li>
            في حال تم استخدام الحساب من قبل عدة أشخاص أو بالنيابة عن منشأة فإن
            الشخص الذي سُجّل الحساب باسمه يكون مسؤولًا عن جميع الأنشطة ويجب أن
            يبلغ مزود الخدمة فورًا على عناوين التواصل الموضحة في هذه الشروط
            والأحكام عن أي استخدام غير قانوني أو غير مصرح به للحساب، أو في حالة
            الشك بأن شخصًا آخر على علم بحساب المستخدم أو كلمة المرور الخاصة
            بالمستخدم.
          </li>
          <li>
            يقر المستخدم بأن لمزود الخدمة الحق في القيام بالتحقق من المعلومات
            التي تمت من قِبل المستخدم على الموقع، وأن لمزود الخدمة الحق في رفض
            أي طلبات غير مكتملة أو غير دقيقة أو تحتوي على معلومات احتيالية أو
            مزورة، كما أنه يحق لمزود الخدمة تأخير إتمام العملية المطلوبة لمزيد
            من تقييم سلامة الوثائق أو في حال وجود صعوبات تقنية، بالإضافة إلى
            أحقية مزود الخدمة في قبول/ رفض/ إلغاء أي عمليات.
          </li>
          <li>
            يقر المستخدم بأنه في حالة حدوث أي أخطاء عند استخدام أي من الخدمات
            المقدمة عبر موقعنا، فإن مزود الخدمة والوزارة لا يتحملان مسؤولية أي
            خسائر مالية أو قانونية مترتبة، وأنه من مسؤولية المستخدم أن يقدم
            تقرير عن الأخطاء من خلال فتح تذكرة دعم إلكترونية عبر مركز التواصل
            المتضمن في بند (طرق التواصل) من هذه الشروط والأحكام وسوف يقوم مزود
            الخدمة أو من ينوبه باتخاذ الإجراء المناسب وإشعار المستخدم به.
          </li>
          <li>
            يقر المستخدم بأنه فوّض مزود الخدمة أو من ينوب عنه بالتحقق من أي
            معلومات مقدمة بهدف الموافقة على طلب المستخدم أو رفضه، أو غيرها من
            إجراءات يتم فيها الاستفادة من البيانات المستخدمة لدى أي من الجهات
            الحكومية والخاصة.
          </li>
        </ul>
        <h2>ثامناً: الاستخدامات المحظورة</h2>
        <p className="text-justify">
          يمكنكم استخدام موقعنا فقط لأغراض مشروعة. ولا يجوز لكم استخدامه
          استخدامات محظورة والتي منها على سبيل المثال لا الحصر:
        </p>
        <ul>
          <li>
            أي طريقة تنطوي على مخالفة أي من القوانين والأنظمة المرعية على
            المستويات المحلية و/أو الوطنية و/أو الدولية.
          </li>
          <li>
            أي طريقة غير قانونية أو احتيالية، أو تنطوي على أي غرض أو يكون لها
            أثر غير قانوني أو احتيالي لغرض إلحاق الضرر أو محاولة إلحاق الضرر
            بالغير.
          </li>
          <li>
            الدخول غير المصرح به، أو التدخل أو إلحاق الضرر أو تعطيل أي جزء من
            الموقع، أو معدات أو شبكة يكون الموقع مخزناً عليها، أو برامج مستخدمة
            في تقديم الموقع وأي معدات أو شبكة أو برامج مملوكة أو مستخدمة من قبل
            أي طرف ثالث.
          </li>
          <li>
            استخدام الموقع بأي طريقة تهدف إلى إلحاق الضرر أو تعطيل أو إثقال أو
            إضعاف أي حاسب خدمة أو شبكة/شبكات مربوطة بحاسب الخدمة الخاص بنا أو
            التدخل في استخدام أي طرف آخر للموقع. ولا يجوز لكم محاولة الدخول غير
            المصرح به على الموقع، أو على أي حسابات أخرى أو أنظمة كمبيوتر أو
            شبكات مربوطة بحاسب الخدمة الخاص بنا من خلال القرصنة واختراق كلمة
            المرور أو أي وسيلة أخرى، كما لا يجوز لكم الحصول أو محاولة الحصول على
            أي مواد أو معلومات مخزنة على الموقع، أو في حاسبات الخدمة أو أجهزة
            الكمبيوتر المرتبطة بها من خلال أي وسيلة لم يتم إتاحتها عن قصد من
            خلال الموقع.
          </li>
          <li>
            القيام عن قصد بإرسال أو استقبال أو تحميل أو تنزيل أو استخدام أو
            إعادة استخدام أي مادة لا تتوافق مع معايير المحتوى المعتمدة لدينا كما
            هو مبين أدناه؛
            <ol>
              <li>
                {" "}
                نقل، أو تكليف من يلزم لإرسال أي إعلانات أو مواد دعائية غير
                مطلوبة أو غير مصرح بها أو أي شكل آخر من أشكال الالتماس المماثل
                (البريد المزعج).
              </li>
              <li>
                {" "}
                القيام عن علم بنقل أي بيانات، وإرسال أو تحميل أي مواد تحتوي على
                فيروسات، أو أحصنة طروادة، أو ديدان، أو متلصصين، أو تجسس، أو أي
                برامج أو فايروسات ضارة أخرى أو رمز كمبيوتر مماثل مصمم ليؤثر
                سلباً على تشغيل أي برامج أو تطبيقات أو أجهزة الكمبيوتر.
              </li>
              <li>
                {" "}
                عدم حذف، أو التحميل بخلاف ذلك بكميات كبيرة، أي محتوى من موقعنا،
                على سبيل المثال لا الحصر، أي قائمة أو دليل مستخدمين محمل على
                النظام، ومنشورات المستخدمين أو معلومات المستخدم. كما أنكم
                توافقون على عدم تحريف أو محاولة تحريف هويتكم أثناء استخدامكم
                الموقع.
              </li>
              <li>
                {" "}
                إعادة إنتاج أو تكرار أو نسخ أو إعادة بيع أي جزء من موقعنا بطريقة
                مخالفة لأحكام شروط استخدام هذا الموقع.
              </li>
              <li>
                {" "}
                تتحمل المنشأة مسؤولية عدم صحة الإجابة في حال عدم مطابقة المعايير
                المطلوبة لاجتياز اختبار التأهيل.
              </li>
              <li>
                {" "}
                تتحمل المنشأة مسؤولية رفض الطلب وعدم إعادة المقابل المالي في ثبت
                أن البيانات المقدمة لاجتياز التأهيل غير صحيحة أو مضللة.
              </li>
            </ol>
          </li>
        </ul>
        <h2>تاسعاً: حدود المسؤولية</h2>
        <p className="text-justify">
          لا تتضمن هذه الشروط والأحكام والإقرارات أي استثناء أو مسؤولية أو تحديد
          لمسؤولية مزود الخدمة عن الوفاة أو الإصابة الشخصية أو الأضرار الجسدية
          أو المعنوية الناتجة على المستخدم جرّاء استخدام الموقع.
          <br />
          إن مزود الخدمة ومديريها وموظفيها ووكلائها والشركات التابعة لها لن
          يكونوا مسؤولين عن تبعات قانونية في مواجهة أي خسائر أو أضرار (سواء
          مباشرة أو غير مباشرة) على الإطلاق الناشئة عن استخدام الموقع بما في ذلك
          دون قيد أو حصر:
        </p>
        <ul>
          <li>الالتزام بأي عقد و/أو اتفاقية يبرمها المستخدم؛</li>
          <li>
            أي مدفوعات إلى حساب خاطئ أو غير صحيح وذلك بسبب المدخلات الخاصة
            بالمستخدم من معلومات غير صحيحة على الموقع؛
          </li>
          <li>
            أي عمليات احتيال أو خداع أو تشويه للحقائق نتيجة أي فعل أو تقصير أو
            إهمال من قبل المستخدم.
          </li>
          <li>
            أي أخطاء أو إساءة في استخدام الموقع أو عدم قدرة أي شخص على استخدام
            الموقع وفهم محتواه؛
          </li>
          <li>
            أي خسائر تنجم عن تأخير الاتصال بالإنترنت أو تعليق الموقع أو الأخطاء
            الناجمة عن فشل أي من وسائل الاتصالات السلكية واللاسلكية أو أي نظام
            لنقل البيانات بما في ذلك عطل الخوادم أو صيانتها أو عدم الوصول إلى
            صفحة المستخدم أو الصفحات المرتبطة بها مع مسؤولية مزود الخدمة عن بذل
            العناية لتقديم الدعم الفني وإصلاح الخلل؛
          </li>
          <li>
            أي خسائر ناتجة عن أعمال أي سلطة حكومية أو غيرها، أو أي فعل قاهر أو
            قوة قاهرة خارج عن إرادة مزود الخدمة.
          </li>
        </ul>
        <p className="text-justify">
          قد يحتوي الموقع على وصلات وروابط مُعلِنين لتطبيقات ومواقع أخرى ولا
          ينبغي تفسير مثل هذه الروابط بأنها معتمدة من جانب مزود الخدمة وبالتالي
          فإننا لا نتحمل أي مسؤولية عن المواد أو الخدمات أو عروض السلع التي تظهر
          على مثل هذه التطبيقات والمواقع، كما لا نضمن خلو أي من التطبيقات
          والمواقع من الفيروسات أو البرامج الضارة أو غيرها ذات الطبيعة المدمرة،
          ويقر ويوافق المستخدم على أن مزود الخدمة والوزارة غير مسؤولين عن محتوى
          أو جودة أي من هذه التطبيقات والمواقع.
          <br />
          في حالة مخالفة المستخدم لهذه الشروط والأحكام، فلنا حق إيقاف أو إلغاء
          أو حجب المستخدم من الوصول إلى الموقع و/أو الحساب.
          <br />
          لا يتحمل مزود الخدمة أية مسؤولية عن أي خسارة أو ضرر ناجم عن فيروس، أو
          دخول غير مشروع للموقع، أو غيرها من المواد الضارة من الناحية التقنية
          التي قد تصيب أجهزة الهاتف المحمول وبرامج الحاسب الآلي/الكمبيوتر أو
          البيانات أو المواد الأخرى العائدة للمستخدم نتيجة استخدامه للموقع أو
          نتيجة قيام المستخدم بتحميل أي محتوى من الموقع، أو من أي مواقع آخر من
          المواقع المرتبطة بموقعنا.
          <br />
          موقعنا غير مسؤول عن أي مشاكل قانونية متعلقة بأموال المستخدم كما يجب أن
          تكون المبالغ المدفوعة من قبل المستخدم هي من ماله الخاص ولا يحق له
          المطالبة بإرجاع المبلغ حال تنفيذ عملية الدفع باستثناء ما نصت عليه
          المادة (الثالثة عشر) من هذه الشروط والأحكام.
          <br />
          لا يتحمل موقعنا بشكل مطلق مسؤولية المشاكل التي قد تنتج من انقطاع
          الإنترنت أو غيرها حين عمليات الدفع كما يرجى من المستخدم مراجعة البنك
          الخاص به في هذه الحالة.
        </p>
        <h2>عاشراً : الربط بموقعنا</h2>
        <ul>
          <li>
            لك حق الربط بصفحتنا الرئيسية، مع مراعاة القيام بذلك بطريقة عادلة
            وقانونية لا تضر بسمعة الموقع أو تُسيء استخدامه.
          </li>
          <li>
            يجب ألا يُفسر هذا الربط كشكل من أشكال الشراكة أو الموافقة بطريقة
            توحي بأي شكل من أشكال مشاركتنا أو موافقتنا صراحةً أم ضمنًا.
          </li>
          <li> يُمنع ربط الاتصال بين موقعنا وبين أي موقع آخر ليس ملكًا لكم.</li>
          <li>
            يجب عدم نشر أي من محتوى موقعنا على أي موقع آخر، كما لا يجوز لكم
            إنشاء رابط لأي جزء من الموقع باستثناء الصفحة الرئيسية.
          </li>
          <li>لنا حق سحب ترخيص الربط الخاص بكم دون إشعار مسبق.</li>
        </ul>
        <h2>الحادي عشر : التعامل مع المنصة من قبل المنشآت </h2>
        <p className="text-justify">
          تشترط المنصة على المنشأة تحقق عدة متطلبات للاستفادة من خدمات المنصة
          والحصول على شهادة موائمة وتتلخص هذه الاشتراطات بالآتي :
        </p>
        <ul>
          <li>تسجيل المنشأة في المنصة وتعيين مستخدم فعلي معتمد من قبلها .</li>
          <li>
            اجتياز اختبار التأهيل المعد من قبل المنصة حيث إن اجتياز المنشأة هو
            العامل الأساس والذي تقوم عليه أهمية الشهادة الممنوحة.
          </li>
          <li>دفع المنشأة للتكلفة المالية المقررة لإصدار الشهادة .</li>
          <li>تحقق معايير اختبار التأهيل.</li>
          <li>إصدار الشهادة آلياً عن طريق المنصة.</li>
        </ul>
        <h2>الرابع عشر : تكاليف الخدمة </h2>
        <div className="text-justify">
          <ol>
            <li>
              {" "}
              تتطلب خدمات المنصة تكلفة مالية نظير الخدمة وقد حددت هذه التكلفة
              بالحد المالي الآتي :
            </li>
            <table className="table table-bordered mt-4 text-center">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">الكيان</th>
                  <th className="text-center">التكلفة المالية</th>
                  <th className="text-center">تكلفة التدريب</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>الصغير والمتوسط</td>
                  <td>
                    11,500
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                  </td>
                  <td>
                    2300
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>الكبير والعملاق</td>
                  <td>
                    28,750
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                  </td>
                  <td>
                    5750
                    <img
                      src={RiyalSymbol}
                      alt="SAR"
                      width="14"
                      height="14"
                      className="me-1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <li>المبالغ المالية المدفوعة ( غير مستردة )</li>
          </ol>
        </div>
        <h2>الخامس عشر :عدم اجتياز المنشأة لاختبارات التأهيل.</h2>
        <div>
          حددت المنصة نسبة 15% كمعيار أدنى لتجاوز المنشأة شروط التأهيل والحصول
          على الشهادة ، وذلك لتحفيز المنشآت للوصول إلى درجة التأهيل إلا أنه في
          حال عدم اجتياز المنشأة لهذا الحد الأدنى من معيار موائمة سيعمل فريق
          المنصة على الآتي :
          <ol>
            <li>
              سيتواصل فريق العمل مع المنشأة لتوضيح الأخطاء الشائعة التي تكون ضمن
              متطلبات التأهيل ولا تتعلق بالأداء أو تأهيل المنشأة كمثال ( عدم
              تعميم الخطاب على الموظفين – عدم وجود ختم اعتماد )
            </li>
            <li>
              سيتواصل فريق العمل مع المنشأة بالرسائل الالكترونية أو الاتصال
              المباشر إذا لزم الأمر لإعادة فتح النظام والدعم للتعريف بمؤشر
              موائمة والرد على الاستفسارات المتعلقة به .
            </li>
            <li>
              يمنح نظام المنصة ثلاث فرص للمنشأة لإعادة فتح النظام ومحاولة رفع
              مستوى التأهيل وفي حال تجاوز هذه الفرص تشعر المنشأة بأهمية الحصول
              على البرنامج التدريبي للتأهيل للحصول على شهادة موائمة ( برنامج
              التدريب اختياري وغير ملزم ).
            </li>
            <li>
              في حال عدم تجاوز المنشأة لشروط التأهيل للمرة الرابعة تشعر المنشأة
              باستنفاد المحاولات ولا تسترد التكلفة المالية المدفوعة للطلب .
            </li>
            <li>
              يتاح للمنشأة رفع طلب جديد بتكلفة مالية جديدة ولا يكون الطلب
              مرتبطاً بالطلب السابق.
            </li>
          </ol>
        </div>
        <h2>السادس عشر :المدة الزمنية لصلاحية طلب الحصول على الشهادة</h2>
        <div className="text-justify">
          حددت مدة الاشتراك للاستفادة من خدمات المنصة بسنة واحدة يتاح فيها
          للمنشأة الاستفادة من الخدمة وتقييم المنشأة وإصدار الشهادة متى تم دفع
          التكلفة المالية المقررة وبمضي هذه المدة ينتهي الالتزام القائم بموجب
          هذا الاشتراك ولن يكون باستطاع المنشأة الاستفادة من خدمات المنصة .
          <br />
          يشمل عدم الاستفادة من خدمات المنصة بعد هذه المدة ما يأتي :
          <ol>
            <li>عدم قبول طلبات التقييم.</li>
            <li>عدم إصدار الشهادة مرة أخرى.</li>
            <li>عدم استرداد التكلفة المالية المقررة كلياً أو جزئياً.</li>
          </ol>
        </div>
        <h2> السابع عشر: التعويض</h2>
        <p>
          باستخدامك لموقعنا فأنت توافق على تأمين وتعويض والدفاع عن الوزارة حسب
          الأنظمة ذات العلاقة وشركة تكامل لخدمات الأعمال و شركاتها الفرعية
          والتابعة والمسؤولين وأعضاء مجلس الإدارة والوكلاء والموظفين من وضد
          ادعاءات أو إجراءات أو مطالبات الأطراف الأخرى الناتجة عن أو المرتبطة
          باستخدامك لموقعنا بأي شكل من الأشكال، شاملًا بذلك أي مسؤوليات أو
          مصاريف ناتجة عن كل أو بعض الادعاءات والخسائر والأضرار (الفعلية
          والتبعية) والدعاوي القضائية والأحكام وتكاليف التقاضي وأتعاب المحامين،
          وفي هذه الحالة، سنزودك بإشعار خطي بهذه المطالبة أو القضية أو الإجراء.
        </p>
        <h2>الثامن عشر: التعليق والانهاء</h2>
        <div className="text-justify">
          سيحدد، وفق تقدير مزود الخدمة، ما إذا كان هناك إخلال بشروط الاستخدام من
          خلال استخدام المستخدم للموقع. إذا تبين وقوع إخلال بالفعل بهذه الشروط
          والأحكام، فقد يتخذ مزود الخدمة الإجراءات التي يراها مناسبة.
          <br />
          عدم الامتثال لهذه الشروط والأحكام والإقرارات أو في حال صدور أعمال
          احتيال أو تزوير من قبل المستخدم قد يؤدي إلى اتخاذ جميع أو أي من
          الإجراءات التالية -وهي على سبيل المثال لا الحصر:
          <ol>
            <li>
              الإيقاف الفوري، بصورة مؤقتة أو دائمة لحق المستخدم في استخدام
              الموقع.
            </li>
            <li>
              الإزالة الفورية، بصورة مؤقتة أو دائمة لأي نشرات أو مواد قام
              المستخدم بتحميلها في الموقع.
            </li>
            <li>توجيه انذار للمستخدم.</li>
            <li>
              اتخاذ إجراءات قانونية بحق المستخدم لطلب التعويض عن كافة التكاليف
              (بما في ذلك، على سبيل المثال لا الحصر، أي اضرار أو تكاليف إدارية
              وقانونية) الناتجة عن الإخلال بهذه الشروط.
            </li>
            <li>
              اتخاذ مزيد من الإجراءات القانونية بحق المستخدم وكشف المعلومات
              للسلطات القضائية المسؤولة عند الحاجة.
            </li>
          </ol>
          نُخلي مسؤوليتنا من الإجراءات المتبعة نتيجة الإخلال بشروط الاستخدام،
          ولا تقتصر تلك الإجراءات على ما جاء ذكره ضمن الشروط، بل يحق لنا اتخاذ
          أي إجراء أو إجراءات أُخرى نراها مناسبة بما لا يخالف الأنظمة والقوانين
          السعودية.
        </div>
        <h2>التاسع عشر: سياسة الدفع والمعاملات المالية</h2>
        <div>
          كافة أسعار الخدمات المعروضة في موقعنا شاملة لضريبة القيمة المضافة.
          <br />
          تكون المعاملات المالية المعتمدة في الموقع من خلال وسائل وطرق الدفع
          الرسمية التالية:
          <ol>
            <li>التحويل البنكي</li>
            <li>الدفع عن طريق سداد</li>
          </ol>
          يلتزم المستخدم بإبلاغنا فوراً بأي عمليات مالية تتم خارج الموقع، حيث لا
          يعترف الموقع بأي عمليات مالية تتم خارج موقعنا.
        </div>
        <h2>العشرون: القانون المطبق</h2>
        <p className="text-justify">
          هذه الشروط والأحكام والإقرارات، وموضوعها وتشكيلها، تخضع لأحكام
          القوانين والأنظمة المرعية في المملكة العربية السعودية، في حال اعتبر أي
          بند من هذه الشروط والأحكام باطلاً أو غير صالح أو غير قابل للتنفيذ
          بموجب القوانين السارية والأنظمة في المملكة العربية السعودية، فإن ذلك
          لن يؤثر على صحة وإلزامية بقية الأحكام والبنود على المستخدمين للموقع،
          ولمحاكم المملكة العربية السعودية – مدينة الرياض الاختصاص الحصري في هذا
          الخصوص. إن اللغة العربية هي اللغة الأساسية لاستخدام الموقع والاستفادة
          من كل المواد المنشورة عليه، ويهدف ترجمة أي من هذه المواد لتقديم خدمة
          مضافة، وعليه فلا يتم الاستناد إلى الترجمة المتوفرة في تفسير أي خلاف
          حول ما يتضمنه الموقع من محتوى بل يتم الاستناد إلى اللغة العربية.
        </p>
        <h2>الحادي والعشرون: طرق التواصل</h2>
        <p className="text-justify">
          يمكن للمستخدم التواصل مع خدمة العملاء في أي موضوع يخص النظام أو الموقع
          أو الخدمات بما في ذلك على سبيل المثال لا الحصر، الاستفسارات العامة،
          والاقتراحات، والمطالبات أو المنازعات، عن طريق التواصل مع مركز خدمة
          العملاء على البريد الإلكتروني (
          <a href="mailto:info@mowaamah.com">info@mowaamah.com</a>) أو عن طريق
          الإشعار الخطي المقدم إلى مقر خدمة العملاء، ولا يعتبر الإشعار مستلماً
          من قبل خدمة العملاء ما لم يصل إلى المستخدم إشعار سواء كان إلكترونيًا
          أو خطيًا يؤكد استلام الإشعار.
          <br />
          جميع الإشعارات والإخطارات سيتم إرسالها على العنوان المسجل في حساب
          المستخدم، ويعتبر التبليغ بهذه الطريقة تبليغاً رسمياً سواءً كان
          إلكترونياً أو بريدياً أو يدوياً، ويتم العمل بها من تاريخ إرسالها.
          <br />
          يلتزم المستخدم بتزويد مزود الخدمة بأي تغيير أو تعديل في عنوانه أو
          معلوماته.
        </p>
      </div>
    </div>
  );
}

export default ArTerms;
