import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import ButtonWithInput from "../../../shared/FormElements/ButtonWithInput";
import onChangeHandler from "../../../utils/onChangeHandler";
import { resetError, resetErrors } from "../../../store/Error/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import {
  callApplyAssessmentDiscountCode,
  callRemoveAssessmentDiscountCode,
  fetchAssessmentOrder,
} from "../../../store/Certificate/actions";
import { Button, Card, Container, Form } from "react-bootstrap";
import BackButton from "../../../shared/BackButton";
import {
  callApplyTrainingDiscountCode,
  callRemoveTrainingDiscountCode,
  fetchTrainingOrder,
} from "../../../store/Training/actions";
import { orderType, companyTypes } from "../../../lookups/status";
import TextInput from "../../../shared/FormElements/TextInput";
import formValidaiton from "../../../utils/formValidaiton";
import RiyalSymbol from "../../../assets/images/Saudi_Riyal_Symbol.svg";
import RiyalSymbolGreen from "../../../assets/images/Saudi_Riyal_Symbol_Green.svg";

const Review = () => {
  let { uuid, companyId } = useParams();
  const dispatch = useDispatch();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const local = useSelector((state) => state.homeReducer.local);
  const query = useQuery();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const errors = useSelector((state) => state.errorReducer.errors);
  const [coupon, setCoupon] = useState({
    code: "",
  });
  const [showDiscount, setShowDiscount] = useState(true);
  const handleOnChange = (e) => {
    onChangeHandler(e, setCoupon, coupon, errors, dispatch, resetError);
  };

  var order = useSelector((state) =>
    query.get("type") === orderType.certificate
      ? state.certificateReducer.assessmentOrder
      : query.get("type") === orderType.training
      ? state.trainingReducer.trainingOrder
      : ""
  );

  const certificateTypeObj = companyTypes(local).find(
    (element) => element?.id === order?.assessment?.name_ar
  );

  const trainingTypeObj = companyTypes(local).find(
    (element) => element?.id === order?.training_requisition?.name_ar
  );

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
    } else {
      navigate(`/${companyId}`);
    }
    dispatch(resetErrors());
  }, []);
  useLegacyEffect(() => {
    if (!order?.pricing_summary?.discount_code) {
      setShowDiscount(true);
    } else {
      setShowDiscount(false);
      setCoupon({ code: order?.pricing_summary?.discount_code });
    }
  }, [order]);
  const handleNextStep = () => {
    navigate(`/${companyId}/payment/${uuid}/method?type=${query.get("type")}`);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationFormData = {
      code: { value: coupon.code, type: "text", required: true },
    };
    const isValidForm = formValidaiton(validationFormData, dispatch);
    if (isValidForm) {
      if (query.get("type") === orderType.certificate) {
        dispatch(callApplyAssessmentDiscountCode(uuid, { ...coupon }));
      } else if (query.get("type") === orderType.training) {
        dispatch(callApplyTrainingDiscountCode(uuid, { ...coupon }));
      }
    }
  };
  const handleRemoveDiscount = () => {
    if (query.get("type") === orderType.certificate) {
      dispatch(callRemoveAssessmentDiscountCode(uuid));
    } else if (query.get("type") === orderType.training) {
      dispatch(callRemoveTrainingDiscountCode(uuid));
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="review mb-5">
      {query.get("type") === orderType.certificate && (
        <ChevronBar
          type={"shape"}
          stepsClass={"steps-flow-container"}
          stepClass={"step-flow"}
          stepText={"step-flow-text"}
          stepTextCurrent={"step-flow-text--current"}
          stepTextUncompleted={"step-flow-text--uncompleted"}
          stepTextCompleted={"step-flow-text--completed"}
          stepCompletedClass={"step-home--completed"}
          steps={flowSteps}
          currentStep={currentCertificateFlowStep}
        />
      )}

      {query.get("type") === orderType.training && (
        <BackButton onClick={() => navigate(-1)} label={local.backV2} />
      )}

      <h1 className="mb-4 fs-3">{local.orderReview}</h1>
      <Card className="shadow p-4">
        <div className="d-flex justify-content-between align-items-center mb-2 text-primary fs-6 fw-bold">
          <p className="mb-0">
            {query.get("type") === orderType.certificate &&
              local.certificateType}
            {query.get("type") === orderType.training && local.trainingType}
          </p>
          <p className="mb-0">{local.price}</p>
        </div>

        <div className="line"></div>

        <div className="d-flex justify-content-between my-4 fs-6 fw-medium">
          <p className="mb-0">
            {query.get("type") === orderType.certificate &&
              local.mowaamahCertificate + certificateTypeObj?.text}
            {query.get("type") === orderType.training &&
              local.training2 + trainingTypeObj?.text}
          </p>
          <p className="mb-0 fs-5">
            <img
              src={RiyalSymbol}
              alt="SAR"
              width="16"
              height="16"
              className="me-1 mb-1"
            />
            {order?.pricing_summary?.price}
          </p>
        </div>

        <div className="line"></div>

        <div className="price-table grid justify-content-between my-4">
          <div className="coupon g-col-12 g-col-md-5 g-col-xl-6">
            <Form
              className="d-flex justify-content-start align-items-center"
              onSubmit={handleSubmit}
            >
              <>
                {showDiscount ? (
                  <TextInput
                    inputId={"code"}
                    type={"text"}
                    label={local.doYouHaveDiscountCode}
                    inputName={"code"}
                    placeholder={local.discountCode}
                    inputValue={coupon.code}
                    isInvalid={errors.code}
                    error={errors.code}
                    onChange={handleOnChange}
                  />
                ) : (
                  <>
                    <p>{local.appliedDiscountCode}</p>
                    <Button
                      variant="outline-success"
                      size="sm"
                      className="mt-2 mx-2 px-4 rounded position-relative"
                      onClick={() => handleRemoveDiscount()}
                    >
                      {coupon.code}
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                        <i className={`bi bi-x`}></i>
                        <span className="visually-hidden">close</span>
                      </span>
                    </Button>
                  </>
                )}
              </>
              {showDiscount && (
                <Button
                  variant="success"
                  className="text-white mt-2 mx-2 px-4"
                  type="submit"
                  size="sm"
                >
                  {local.activate}
                </Button>
              )}
            </Form>
          </div>
          <div className="empty d-none d-md-block g-col-12 g-col-md-2 g-col-xl-2"></div>
          <div className="amount g-col-12 g-col-md-5 g-col-xl-4">
            <div className="d-flex justify-content-between my-2 fs-6 fw-medium">
              <p className="mb-0">{local.discount}</p>
              <p className="mb-0 fs-5">
                <img
                  src={RiyalSymbol}
                  alt="SAR"
                  width="16"
                  height="16"
                  className="me-1 mb-1"
                />
                {order?.pricing_summary?.discounted_amount}
              </p>
            </div>
            <div className="d-flex justify-content-between fs-6 fw-medium">
              <p className="mb-2">{local.totalWithoutVat}</p>
              <p className="mb-0 fs-5">
                <img
                  src={RiyalSymbol}
                  alt="SAR"
                  width="16"
                  height="16"
                  className="me-1 mb-1"
                />
                {order?.pricing_summary?.total_before_vat}
              </p>
            </div>
            <div className="d-flex justify-content-between fs-6 fw-medium">
              <p className="mb-2">{local.vat}</p>
              <p className="mb-0 fs-5">
                <img
                  src={RiyalSymbol}
                  alt="SAR"
                  width="16"
                  height="16"
                  className="me-1 mb-1"
                />
                {order?.pricing_summary?.vat_amount}
              </p>
            </div>
          </div>
        </div>

        <div className="line"></div>
        <div className="grid mt-4 review-total justify-content-between align-items-stretch">
          <div className="d-none d-md-block empty g-col-12 g-col-md-7 g-col-xl-8"></div>
          <div className="g-col-12 g-col-md-5 g-col-xl-4 justify-content-between total-amount">
            <div className="d-flex w-100 justify-content-between">
              <p className="fs-5 text-success fw-bold d-inline mb-0">
                {local.totalCost}
              </p>
              <p className="fs-4 text-success fw-bold d-inline mb-0">
                <img
                  src={RiyalSymbolGreen}
                  alt="SAR"
                  width="16"
                  height="16"
                  className="me-1 mb-1"
                />
                {order?.pricing_summary?.grand_total}
              </p>
            </div>
          </div>
        </div>
      </Card>

      <div className="row justify-content-center mt-5 text-center">
        <div className="col-12 col-md-4">
          <Button
            variant="secondary"
            className="w-100 text-white"
            onClick={handleNextStep}
          >
            {local.processToPay}
          </Button>
        </div>
      </div>

      {query.get("type") === orderType.certificate && (
        <div className="text-center  mt-4">
          <Button
            onClick={() => navigate(`/${companyId}`)}
            variant="link"
            className="text-secondary fs-5"
            type="button"
          >
            {local.backToHomePage}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default Review;
