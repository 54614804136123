import React, { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import Zendesk, { ZendeskAPI } from "../../../utils/zendeskConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ContactPage() {
  const local = useSelector((state) => state.homeReducer.local);
  const [showContactForm, setShowContactForm] = useState(false);
  const handleLoaded = () => {
    ZendeskAPI(
      "messenger:set",
      "locale",
      localStorage.getItem("prefered_language")
    );
    ZendeskAPI("messenger", "open");
  };

  return (
    <section className="contact-page">
      <Container>
        <Row className="mb-5">
          <Col>
            <h1 className="text-center text-primary mb-5 font-weight-bold">
              {local.contactUs}
            </h1>
          </Col>
        </Row>

        <Row className="justify-content-center mx-auto mb-4">
          <Col xs={12} xl={8} className="border p-4 px-xl-5">
            <Row className="align-items-center justify-content-between">
              <Col xs={12} md={7}>
                <p className="fs-4 font-weight-bold mb-1">
                  {local.directMessages}
                </p>
                <p className="mb-0 fs-5">{local.directMessagesNote}</p>
              </Col>
              <Col xs={12} md={5} className="d-flex justify-content-end">
                <Button
                  className="sendMsg"
                  variant="outline-primary"
                  onClick={() => {
                    setShowContactForm(true);
                  }}
                >
                  {local.sendMessage}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mx-auto mb-4">
          <Col xs={12} xl={8} className="border p-4 px-xl-5">
            <Row className="align-items-center justify-content-between g-5">
              <Col xs={12} md={7}>
                <p className="fs-4 font-weight-bold mb-1">{local.whatsapp}</p>
                <p className="mb-0 fs-5">{local.whatsappNote}</p>
              </Col>
              <Col xs={12} md={5} className="d-flex justify-content-end">
                <a
                  href="https://api.whatsapp.com/send?phone=966114076459"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="outline-primary">
                    <i className="bi bi-box-arrow-up-right ms-1"></i>{" "}
                    {local.startConversation}
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* for video calling feature */}
        {/* <Row className="justify-content-center mx-auto mb-4">
          <Col xs={12} xl={8} className="border p-4 px-xl-5">
            <Row className="align-items-center justify-content-between g-5">
              <Col xs={12} md={7}>
                <p className="fs-4 font-weight-bold mb-1">
                  {local.signLanguageSupport}
                </p>
                <p className="mb-0 fs-5">{local.videoCall}</p>
              </Col>
              <Col xs={12} md={5} className="d-flex justify-content-end">
                <Link to="/customer-video-calling-service">
                  <Button variant="outline-primary">
                    <i className="bi bi-box-arrow-up-right ms-1"></i>
                    {local.startVideoCall}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row> */}
        <Row className="justify-content-center mx-auto mb-4">
          <Col xs={12} xl={8} className="border p-4 px-xl-5">
            <Row className="align-items-center justify-content-between">
              <Col xs={12} md={9}>
                <p className="fs-4 font-weight-bold mb-1 text-center text-md-end">
                  {local.customerService}
                </p>
              </Col>
              <Col xs={12} md={3} className="d-flex justify-content-end fs-3">
                <a href="tel:920022476">920022476</a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mx-auto mb-4">
          <Col xs={12} xl={8} className="border p-4 px-xl-5">
            <Row className="align-items-center justify-content-between">
              <Col xs={12} md={9}>
                <p className="fs-4 font-weight-bold mb-1 text-center text-md-end">
                  {local.mowaamahEmail}
                </p>
              </Col>
              <Col xs={12} md={3} className="d-flex justify-content-end fs-4">
                <a href="mailto:info@mowaamah.com">info@mowaamah.com</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {showContactForm && (
        <Zendesk
          defer
          zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
          onLoaded={handleLoaded}
        />
      )}
    </section>
  );
}

export default ContactPage;
